<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                                    <b-row>
                                      <b-col sm="12">
                                          <ValidationProvider name="Remarks" vid='gdn_remarks_id' rules="required|min_value:1">
                                              <b-form-group
                                                  class="row"
                                                  label-cols-sm="12"
                                                  label-for="gdn_remarks_id"
                                                  slot-scope="{ valid, errors }"
                                                  >
                                                  <template v-slot:label>
                                                      {{$t('globalTrans.remarks')}}<span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-select
                                                  plain
                                                  v-model="data.gdn_remarks_id"
                                                  :options="masterBtriRemarksList"
                                                  id="gdn_remarks_id"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  >
                                                  <template v-slot:first>
                                                      <b-form-select-option :value="0">{{  $t('globalTrans.select') }}</b-form-select-option>
                                                  </template>
                                                  </b-form-select>
                                                  <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                                  </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                      </b-col>
                                    </b-row>
                                    <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        &nbsp;
                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('garden_remarks')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { btriTestGardenRemarksSubmitApi } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id', 'service_id'],
    created () {
    },
    watch: {
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.localizeUserList(newVal)
        }
      },
      'data.gdn_remarks_id': function (newVal, oldVal) {
        if (newVal) {
          this.getRemarksName(newVal)
        } else {
          this.data.gdn_remarks_en = ''
          this.data.gdn_remarks_bn = ''
        }
      }
    },
    computed: {
        loading () {
          return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        masterBtriRemarksList () {
          // 2 => btri, 4 => garden
          return this.$store.state.TeaGardenService.commonObj.masterRemarkList.filter(item => item.status === 1 && JSON.parse(item.type).includes(2) && JSON.parse(item.type).includes(4))
        }
    },
    data () {
        return {
            saveBtnName: this.$t('globalTrans.submit'),
            data: {
                gdn_remarks_id: 0,
                gdn_remarks_bn: '',
                gdn_remarks_en: ''
            },
            loader: false
        }
    },
    methods: {
      async register () {
          this.loader = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadinState = { loading: false, listReload: true }
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${btriTestGardenRemarksSubmitApi}/${this.id}`, this.data)
          this.$store.dispatch('mutateCommonProperties', loadinState)
          if (result.success) {
              this.$store.dispatch('BftiResearchAndReportService/mutateCommonObj', { hasDropdownLoaded: false })
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })
              this.$bvModal.hide('garden_remarks')
          } else {
              this.$refs.form.setErrors(result.errors)
          }
          this.loader = false
      },
      getRemarksName (id) {
        const obj = this.masterBtriRemarksList.find(item => item.value === id)
        if (obj) {
          this.data.gdn_remarks_en = obj?.text_en
          this.data.gdn_remarks_bn = obj?.text_bn
        }
      }
    }
}
</script>
